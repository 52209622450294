// import { getFundingHistory } from "@/api/FundingRate.js";

const config = {
  supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', 'D']
}
const api_root = 'https://min-api.cryptocompare.com'
const rp = require('request-promise').defaults({json: true});

/*
var io = require('socket.io-client');
var socket_url = 'wss://streamer.cryptocompare.com';
var socket = io(socket_url);
// 正在订阅的对象数组
var _subs = [];*/

const requestBars = async (symbol, to, resolution) => {
  const split_symbol = symbol.split(/[:/]/)
  const url = resolution === 'D' ? '/data/histoday' : resolution >= 60 ? '/data/histohour' : '/data/histominute'
  const qs = {
    e: split_symbol[0], // Coinbase
    fsym: split_symbol[1], // BTC
    tsym: split_symbol[2], // USD
    toTs:  to ? to : '',
    limit: 2000,
  }
  const data = await rp({
    url: `${api_root}${url}`,
    qs,
  })
  let bars = [];
  if (data.Response && data.Response === 'Error') {
    console.log('CryptoCompare API error:', data.Message)
  } else if (data.Data.length) {
    bars = data.Data.map(el => ({
      time: el.time * 1000,
      low: el.low,
      high: el.high,
      open: el.open,
      close: el.close,
      volume: el.volumefrom
    }))
  }

  return bars;
}

export class JsapiObj {
  //设置图表库支持的图表配置
  onReady(callback) {
    setTimeout(() => {
      callback({
        //交易所对象数组参数传递
        // exchanges: [
        //   // data.exchanges.map((o) => ({
        //   //   value: o.val,
        //   //   name: o.name,
        //   //   desc: o.desc,
        //   // })),
        // ],
        /* exchanges: [
          {
            value: 'Bitfinex',
            name: 'Bitfinex',
            desc: 'Bitfinex',
          },
          {
            // `exchange` `searchSymbols` 方法的参数，如果用户选择此交换
            value: 'Kraken',

            // 过滤器名称
            name: 'Kraken',

            // 过滤器弹出窗口中显示的完整交易所名称
            desc: 'Kraken bitcoin exchange',
          },
        ],
        symbols_types: [
          {
            name: 'crypto',

            // `searchSymbols` 方法的 `symbolType` 参数，如果用户选择了这个符号类型
            value: 'crypto',
          },
        ],
        supports_search: true,*/
        //支持的时间间隔,5分钟,1小时,1天
        supported_resolutions: config.supported_resolutions,
        //货币转换支持的货币数组
        currency_codes: ['USD', 'USDT'],
        supports_marks: false,
      })
    })
  }

  //搜索框配置
  async searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    /*var data = await getBars()
    onResultReadyCallback({
      result: [
        {
          //商品缩写名
          symbol: B,
          //商品全称
          full_name: BTC,
          //商品描述
          description: currency,
          //交易所名
          exchange: Binance,
          //类型
          type: bitcoin,
        },
        {
          symbol: E,
          full_name: ETH,
          description: currency,
          exchange: Binance,
          type: bitcoin,
        },
      ],
    })*/
  }

  //商品解析
  resolveSymbol(
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) {
    const split_data = symbolName.split(/[:/]/);

    const symbol_stub = {
      name: symbolName,
      ticker: symbolName,
      description: '',
      type: 'crypto',
      session: '24x7',
      //session_holidays: YYYYMMDD,
      timezone: 'Asia/Shanghai',
      format: 'price',
      minmov: 1,
      pricescale: 100000000,
      //支持日内历史数据
      has_intraday: true,
      intraday_multipliers: ['1', '60'],
      has_daily: true,
      has_weekly_and_monthly: true,
      //周期
      supported_resolutions: config.supported_resolutions,
      volume_precision: 8,
    }

    if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
      symbol_stub.pricescale = 100
    }

    setTimeout(() => onSymbolResolvedCallback(symbol_stub))
  }

  //商品解析
  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    let bars = [];
    let firstTime = periodParams.to
    do {
      const data = await requestBars(symbolInfo.name, firstTime, resolution)
      if (data.length > 0) {
        firstTime = data[0].time / 1000
        bars = bars.concat(data)
      } else {
        firstTime = 0
      }
    } while (firstTime > periodParams.from)

    if (bars.length > 0) {
      let hash = {};
      bars = bars.reduce((item, next) => {
        hash[next.time] ? '' : hash[next.time] = true && item.push(next);
        return item
      }, []).sort((a,b) => a.time - b.time)
    }
    //渲染历史数据
    onHistoryCallback(bars, {noData: bars.length === 0})
  }

  //订阅K线数据
  async subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) {
    /*const channelString = createChannelString(symbolInfo);
    socket.emit('SubAdd', { subs: [channelString] });

    var newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
      listener: updateCb,
    };
    _subs.push(newSub);*/
  }

  //取消订阅K线数据
  async unsubscribeBars(subscriberUID) {
    console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID)
  }
}

// 将symbolInfo对象作为输入参数，并创建要发送到CryptoCompare的订阅字符串
function createChannelString(symbolInfo) {
  var channel = symbolInfo.name.split(/[:/]/);
  const exchange = channel[0] === 'GDAX' ? 'Coinbase' : channel[0];
  const to = channel[2];
  const from = channel[1];
  // 订阅指定交易所和交易对的CryptoCompare交易频道
  return `0~${exchange}~${from}~${to}`;
}
